import React from 'react';
import { Category } from './category';
import { Tags } from './tags';
import { MetaWrap } from './blog-meta.stc';

export const BlogMeta = ({ children, ...restProps }) => {
  return <MetaWrap {...restProps}>{children}</MetaWrap>;
};

export const DateWrap = ({ children, ...restProps }) => {
  return (
    <MetaWrap {...restProps}>
      <span role="img">📅</span> {children}
    </MetaWrap>
  );
};

export { Category, Tags };
