import styled from 'styled-components';
import { space } from 'styled-system';
import { themeGet } from '@styled-system/theme-get';

export const CategoryWrap = styled.div`
  ${space}
  a {
    display: inline-block;
    position: relative;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 12px;
    color: ${themeGet('colors.headingColor')};
    &:after {
      position: absolute;
      content: '';
      left: 0px;
      bottom: 2px;
      background-color: ${themeGet('colors.themeColor')};
      width: 50px;
      height: 4px;
      z-index: -1;
    }
  }
`;
